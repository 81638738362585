@font-face {
  font-family: tajawal;
  src: url(/public/assets/fonts/tajawal/Tajawal-Regular.ttf) format('truetype');
}

@font-face {
  font-family: tajawal-medium;
  src: url(/public/assets/fonts/tajawal/Tajawal-Medium.ttf) format('truetype');
}

@font-face {
  font-family: tajawal-bold;
  src: url(/public/assets/fonts/tajawal/Tajawal-Bold.ttf) format('truetype');
}
body {
  font-family: tajawal;
  font-weight: 700;

}
  
  .stream {
    width: 100%;
    height: 500px;
  }

  .main-wrapper{
    background:#edf5fc
  }
  section{
    margin-bottom: 20px;
  }