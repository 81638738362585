li a{
  font-size: 15px;
  font-weight: 700;
  color: #2E3842
}
.myBtn{
  height: 42px;
  font-size: 15px;
  background: #09e5ab;
  color: white;
  font-weight: bold;
  border-radius: 36px;
  border: none;
  padding: 10px;
  cursor: pointer;
  outline: none;
  box-shadow: 3px 3px 18px 0px #b9b9b9;
  transition: 0.3s;

  }

  .myBtn:hover{
      color: black;
      background-color: white
  }

  .myBtn i{
      margin-left: 8px
  }

  .fullScreen{
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: white;
      display: none;
      z-index: 2;
      width: 100%;
      height: 100%;
   

  }

  .inputBox{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: white;
      padding: 2px;
      border-radius: 3px;
      box-shadow : inset 0 0.25rem 0.125rem 0 rgb(0 0 0 / 5%);
      display: flex;
  }

  .inputBox input{
      height: 45px;
      width: 270px;
      border: none;
      outline: none;
      font-size: 19px;
      color: #4a4a4a;
      padding-left: 15px
  }

  .inputBox button{
      border: none;
      background-color: transparent;
      vertical-align: middle;
      cursor: pointer;
      transition: 0.5s
  }

  .inputBox button:hover{
      transform: rotate(360deg);

  }

  .inputBox button i{
      font-size: 30px
  }

  .closeBtn{
      height: 30px;
      width: 30px;
      background-color: white;
      border-radius: 16px;
      position: absolute;
      top: 62px;
      right: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
  }