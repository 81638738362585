/* .react-tel-input .form-control {
    position: relative !important;
    font-size: 14px !important;
    letter-spacing: .01rem !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 48px !important;
    margin-left: 0 !important;
    background: #FFFFFF !important;
    border: 1px solid #CACACA !important;
    border-radius: 5px !important;
    line-height: 25px !important;
    height: 35px !important;
    width: 100% !important;
    outline: none !important;
}


.react-tel-input .selected-flag {
    outline: none;
    position: relative;
    width: 38px;
    height: 100%;
    padding: 0 0 0 8px;
    border-radius: 3px 0 0 3px;
} */


.react-tel-input .flag-dropdown {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0;
    background-color: #f5f5f5;
    border: 1px solid #cacaca;
    border-radius: 3px 0 0 3px;
    left: 0 !important;
}
.react-tel-input .selected-flag .flag {
    position: absolute;
    top: 50%;
    margin-top: -5px;
    left: 11px;
}

.react-tel-input .selected-flag .arrow {
    position: absolute;
    top: 50%;
    margin-top: -2px;
    left: 18px;
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 4px solid #555;
}
.react-tel-input .form-control {
    padding-left: 48px !important;
    text-align: left !important;
}